.custom-scroll {
  &::-webkit-scrollbar {
    height: var(--scrollbar-width);
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-thumb {
    background: #889097;
    border-radius: var(--scrollbar-width);
  }
}