body {
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  line-height: var(--base-line-height);
  color: var(--base-font-color);
  background-color: var(--base-body-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--base-font-family);
}

h1 {
  font-size: 42px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.1px;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0;
}

h3 {
  font-size: 22px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.5px;
}

h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.5px;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.3px;
}

img {
  vertical-align: initial;
}
